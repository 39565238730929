import { AchievementsSearch } from "@/domain/account.interface";
import Link from "next/link";
import Image from "next/image";
import _ from "lodash";
import { useParams } from "next/navigation";

export type AchievementItemProps = {
  recentSearch: AchievementsSearch;
  onSave: (recentSearch: AchievementsSearch) => void;
  handleOnClose: () => void;
}

const AchievementItem = ({ 
  recentSearch, 
  onSave, 
  handleOnClose
}: AchievementItemProps) => {
  const params = useParams();
  const { locale } = params;
  
  return (
    <Link
      href={`/${locale}/achievements/${recentSearch.slug || recentSearch.id}` || '/'}
      key={`${recentSearch.id}`}
      className="flex flex-col gap-2 items-start mb-2 w-full justify-center hover:bg-white-600/20 px-1 py-2"
      onClick={() => {
        onSave(recentSearch);
        handleOnClose();
      }}
    >
      <div className="flex gap-2 items-start w-full">
        <Image 
          src={recentSearch.image || `${process.env.NEXT_PUBLIC_DEFAULT_PROFILE_PIC}`} 
          alt={recentSearch.title} 
          width={40} 
          height={40}
          className="rounded w-10 h-10"
        />
        <div className="flex flex-col">
          <span className="text-body-md text-white leading-relaxed">{recentSearch.title}</span>
          <span className="text-body-sm text-black-400">{recentSearch.game?.name} - @{recentSearch.user?.username}</span>
        </div>
      </div>
    </Link>
  )
};

export default AchievementItem;