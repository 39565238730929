// database.config.ts
import Dexie, { Table } from 'dexie';

export interface Recent {
  type: string, 
  id?: number;
  link: string,
  title: string, 
  username: string, 
  image: string
  query?: string, 
  game?: string, 
  eventType?: string, 
}

export interface RecentSearch {
  recentSearches: Recent[];
}

export interface RecentProfile {
  key: string,
  name: string,
  icon: string,
  iconHoverColor: string,
  bgColor: string,
  activeBgColor: string,
  href: string
}

export interface RecentProfiles {
  profiles: RecentProfile[];
}

export const removeRecentProfile = async (recenProfiles: RecentProfiles, pageKey: string) => {
  return recenProfiles?.profiles.filter((page: any) => page.key !== pageKey);
};

export class MySubClassedDexie extends Dexie {
  recentSearches!: Table<RecentSearch>; 
  recentProfiles!: Table<RecentProfiles>; 

  constructor() {
    super('apgIndexedDB');
    this.version(1).stores({
      recentSearches: ',*recentSearches',
      recentProfiles: ',*profiles',
    });

    this.open().catch(function (e) {
      console.error("Open failed: " + e.stack);
    })
  }
}

export const db = new MySubClassedDexie();