import { ApgNotification } from "@/lib/notification.context";
import { cn } from "@apg.gg/core/lib/cn";
import CalendarIcon from "@apg.gg/icons/lib/CalendarIcon";
import Image from "next/image";
import { formatDistance } from 'date-fns';
import { useTranslations } from 'next-intl';

export type EventPayloadParticipant = {
  user: {
    id: number;
    username: string;
    profileImage: string;
  }
}

export type EventPayload = {
  eventSlug: string;
  eventTitle: string;
  lastParticipantUsername: string;
  participants: EventPayloadParticipant[]
}

export type Step = {
  digest: boolean;
  totalCount: number;
}

const NotificationEventTemplate: React.FC<{ 
  notification: ApgNotification, 
  selectedLocale: Locale,
  payload: EventPayload
}> = ({ 
  notification, 
  selectedLocale, 
  payload
}) => {
  const t = useTranslations('notifications');
  const step = (notification.content as string).includes("digest:") ? JSON.parse((notification.content as string).replace(/(\w+):/g, '"$1":')) : notification.content as string;

  const handleNotificationContent = (step: Step, title: string, participants: EventPayloadParticipant[]) => {
    let lastParticipantUsername = participants[0]?.user?.username || '';
  
    if (step.digest) {
      let content = t('joined', { username: lastParticipantUsername });
  
      if (step.totalCount === 1) {
        content = t('singleJoin', { username: lastParticipantUsername, eventTitle: title });
      } else if (step.totalCount === 2) {
        const secondParticipantUsername = participants[1]?.user?.username || '';
        content = t('doubleJoin', { username1: lastParticipantUsername, username2: secondParticipantUsername, eventTitle: title });
      } else {
        content = t('multipleJoin', { username: lastParticipantUsername, count: step.totalCount - 1, eventTitle: title });
      }
  
      return content;
    } else {
      return t('singleJoin', { username: lastParticipantUsername, eventTitle: title });
    }
  };

  const renderImages = (step: Step, participants: EventPayloadParticipant[]) => {
    if (step.digest) {
      if (step.totalCount === 1) {
        return (
          <Image src={participants[0].user.profileImage || `${process.env.NEXT_PUBLIC_DEFAULT_PROFILE_PIC}`} alt='' width={60} height={60} className="w-6 h-6 rounded-full object-cover object-center" />
        );
      } else if (step.totalCount === 2) {
        return (
          <div className="flex gap-1">
            <Image src={participants[0].user.profileImage || `${process.env.NEXT_PUBLIC_DEFAULT_PROFILE_PIC}`} alt='' width={60} height={60} className="w-6 h-6 rounded-full object-cover object-center" />
            <Image src={participants[1].user.profileImage || `${process.env.NEXT_PUBLIC_DEFAULT_PROFILE_PIC}`} alt='' width={60} height={60} className="w-6 h-6 rounded-full object-cover object-center" />
          </div>
        );
      } else {
        return (
          <div className="flex gap-1">
            {participants.map((participant, index) => (
              <Image key={`${participant.user.username}-${index}`} src={participant.user.profileImage || `${process.env.NEXT_PUBLIC_DEFAULT_PROFILE_PIC}`} alt={participant.user.username} width={60} height={60} className="w-6 h-6 rounded-full object-cover object-center" />
            ))}
          </div>
        );
      }
    } else {
      return (
        <Image src={participants[0].user.profileImage || `${process.env.NEXT_PUBLIC_DEFAULT_PROFILE_PIC}`} alt='' width={60} height={60} className="w-6 h-6 rounded-full object-cover object-center" />
      );
    }
  }

  return (
    <>
      <div className="flex items-center justify-center w-9 h-9 bg-red rounded-full">
        <CalendarIcon className="text-2xl object-cover " />
      </div>
      <div className="flex flex-1 flex-col gap-1">
        {renderImages(step, payload.participants)}
        
        <span className={cn("text-sm leading-4", notification?.read ? "text-gray-400" : "text-white")}>{handleNotificationContent(step, payload.eventTitle, payload.participants)}</span>
        <span className={cn("text-[10px] text-white-300")}>{formatDistance(new Date(notification?.createdAt), new Date(), { addSuffix: true, locale: selectedLocale })}</span>
      </div>
    </>
  );
}

export default NotificationEventTemplate;