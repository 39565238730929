
import { useMutation } from "@tanstack/react-query"
import { toast } from "react-toastify";
import { QuestEndpoints } from "@/graph/profile-quests.endpoints";

const useClaim = (
  userId: number, 
  token: string, 
  missionId: number,
  claimSucess: string
) => {
  
  return useMutation(
    {
      mutationFn: () => QuestEndpoints.claimReward({ userId, missionId, token }),
      onSuccess: () => {
        toast.success(claimSucess);
      },
      onError: () => {
        toast.error('Error claiming the reward. Please try again later.');
      }
    }
  );
}

export default useClaim;