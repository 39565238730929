import { FC, useEffect, useState } from 'react';
import { useTranslations } from 'next-intl';
import { cn } from '@/lib/utils';
import { ApgNotification, useNotification } from '@/lib/notification.context';
import XIcon from '@apg.gg/icons/lib/XIcon';
import Link from 'next/link';
import { es, enGB, ptBR } from 'date-fns/locale';
import Button from '@apg.gg/core/lib/Button';
import SettingsIcon from '@apg.gg/icons/lib/SettingsIcon';
import NotificationEventTemplate from './Notifications/EventNotification';
import FollowNotification, { FollowPayload } from './Notifications/FollowNotification';
import VouchNotification, { VouchPayload } from './Notifications/VouchNotification';
import Dropdown from '@apg.gg/core/lib/Dropdown';
import NotificationIcon from '@apg.gg/icons/lib/NotificationIcon';
import { useParams } from 'next/navigation';
import CustomNotification, { CustomPayload } from './Notifications/CustomNotification';

type EventPayload = {
  eventSlug: string;
  eventTitle: string;
  lastParticipantUsername: string;
  participants: {
    user: {
      id: number;
      username: string;
      profileImage: string;
    }
  }[]
}

const NotificationCenter: FC = () => {
  const t = useTranslations('notifications');
  const { locale } = useParams();
  const [notificationsMenuOpen, setNotificationsMenuOpen] = useState<boolean>(false);
  const { notifications, markNotificationsAsRead, deleteNotification, pageNum, unseenCount, messagesIds, fetchNotifications } = useNotification();

  const dateLocale: { [key: string]: Locale } = {
    es: es,
    en: enGB,
    pt: ptBR
  }

  const selectedLocale = dateLocale[locale as string];

  const handleNotificationRead = (notificationId: string) => {
    markNotificationsAsRead(notificationId);
  }

  const handleNotificationDelete = (notificationId: string) => {
    deleteNotification(notificationId);
  }

  const handleOnClose = () => {
    setNotificationsMenuOpen(false);
  }

  useEffect(() => {
    if (notificationsMenuOpen) {
      fetchNotifications();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationsMenuOpen]);

  return (
    <Dropdown
      placement="bottomRight"
      trigger={["click"]}
      visible={notificationsMenuOpen}
      onVisibleChange={(visible) => setNotificationsMenuOpen(visible) }
      overlayClassName="bg-black-900 shadow-md rounded p-4 z-50"
      content={
        <div className="flex flex-col gap-4 w-72 md:w-80">
          <div className="flex justify-between items-center">
            <h5 className="text-blue-400 text-label-lg uppercase">{t("title-notifications")}</h5>
            <div className="flex gap-1">
              <Button
                type="ghost"
                onClick={() => markNotificationsAsRead(messagesIds as string[])}
                fontSize='xs'
              >
                {t("mark-all-as-read")}
              </Button>
              <Button
                type="ghost"
                icon={<SettingsIcon />}
                onClick={handleOnClose}
                disabled={true}
              ></Button>
            </div>
          </div>

          <div className="scrollbar-small-content">
            <div className={cn(
              "overflow-y-auto h-[calc(100vh-136px)] space-y-2 pr-1",
            )}>
              {notifications.map((notification: ApgNotification) => {
                const { cta, templateIdentifier, payload } = notification;
                const url = cta?.data?.url;
                const NotificationComponent = url ? Link : "div";

                return (
                  <div
                    key={notification.id}
                    className={cn(
                      "flex gap-2 items-start w-full justify-start hover:bg-white-600/20 px-3 py-2 rounded-xl cursor-pointer relative min-h-14 group",
                      notification?.read ? "bg-black/20" : "bg-black-800"
                    )}
                    id={`${notification.id}`}
                  >
                    <NotificationComponent
                      href={url ? `/${locale}${url}` : "#"}
                      className="flex w-full gap-2"
                      onClick={() => {
                        handleNotificationRead(notification.id);
                        handleOnClose();
                      }}
                    >
                      {templateIdentifier === "custom-message" ? (
                        <CustomNotification notification={notification} selectedLocale={selectedLocale} payload={payload as CustomPayload} />
                      ) : null}

                      {templateIdentifier === "new-participant-on-my-event" ? (
                        <NotificationEventTemplate notification={notification} selectedLocale={selectedLocale} payload={payload as EventPayload} />
                      ) : null}

                      {templateIdentifier === "follow-user" ? (
                        <FollowNotification notification={notification} selectedLocale={selectedLocale} payload={payload as FollowPayload} />
                      ) : null}

                      {templateIdentifier === "new-vouch" ? (
                        <VouchNotification notification={notification} selectedLocale={selectedLocale} payload={payload as VouchPayload} />
                      ) : null}
                    </NotificationComponent>

                    <div
                      className="hidden absolute top-2 right-2 group-hover:block cursor-pointer"
                      role="button"
                      onClick={(e: any) => {
                        e.preventDefault();
                        handleNotificationDelete(notification.id)
                      }}
                    >
                      <XIcon />
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      }
    >
      <Button
        onClick={() => {
          setNotificationsMenuOpen(!notificationsMenuOpen)
        }}
        type="outline"
        className="w-8 h-8 !px-0 relative"
        icon={<NotificationIcon />}
      >
        {unseenCount > 0 ? (
          <div className="flex absolute -top-1.5 -right-1 text-xs h-4 w-4 bg-blue-400 items-center justify-center whitespace-nowrap text-white rounded-full">
            <span className="">{unseenCount}</span>
          </div>
        ) : null}
      </Button>
    </Dropdown>
  );
};

export default NotificationCenter;
