import { ApgNotification } from "@/lib/notification.context";
import { cn } from "@apg.gg/core/lib/cn";
import Image from "next/image";
import { formatDistance } from 'date-fns';
import { useTranslations } from 'next-intl';

export type FollowPayload = {
  username: string;
  profileImage: string;
}

const FollowNotification: React.FC<{ 
  notification: ApgNotification, 
  selectedLocale: Locale,
  payload: FollowPayload
}> = ({ 
  notification, 
  selectedLocale, 
  payload
}) => {
  const t = useTranslations('notifications');

  return (
    <>
      <div className="flex">
        <Image
          src={payload.profileImage as string || `${process.env.NEXT_PUBLIC_DEFAULT_PROFILE_PIC}`}
          alt={payload.username as string}
          width={32}
          height={32}
          className="rounded-full w-8 h-8 object-cover"
        />
      </div>
      <div className="flex flex-col gap-0">
        <span
          className={cn(
            "text-sm leading-4",
            notification?.read ? "text-gray-400" : "text-white"
          )}
        >
          {t('followed', { username: payload.username })}
        </span>
        <span
          className={cn(
            "text-[10px] text-white-300"
          )}
        >
          {formatDistance(new Date(notification?.createdAt), new Date(), { addSuffix: true, locale: selectedLocale })}
        </span>
      </div>
    </>
  )
}

export default FollowNotification;