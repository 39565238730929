import { UserSearch } from "@/domain/account.interface";
import Tag from "@apg.gg/core/lib/Tag";
import Link from "next/link";
import Image from "next/image";
import _ from "lodash";
import { useParams } from "next/navigation";

const UserItem = ({ 
  user,
  onSave,
  handleOnClose
}: { 
  user: UserSearch,
  onSave: (recentSearch: UserSearch) => void;
  handleOnClose: () => void;
 }) => {
  const params = useParams();
  const { locale } = params;
  const profileTypesOrdered = _.orderBy(user.profileTypes, ['order'], ['asc']);

  return (
    <Link
      href={`/${locale}/${user.username}` || '/'}
      key={`${user.id}`}
      className="flex flex-col gap-2 items-start w-full justify-center hover:bg-white-600/20 px-1 py-2"
      onClick={() => {
        onSave(user);
        handleOnClose();
      }}
    >
      <div className="flex gap-2 items-start">
        <Image 
          src={user.profileImage || `${process.env.NEXT_PUBLIC_DEFAULT_PROFILE_PIC}`} 
          alt={user.username} 
          width={40} 
          height={40}
          className="rounded-full overflow-hidden w-10 h-10"
        />
        <div className="flex flex-col gap-2">
          <div className="flex flex-col">
            <span className="text-body-md text-white">{user.name}</span>
            <span className="text-body-sm text-black-400">@{user.username}</span>
          </div>
          <div className="flex gap-1">
            {profileTypesOrdered.slice(0, 1).map((profileType, index) => (
              <Tag 
                key={`${profileType?.id}-${index}`}
                type={profileType?.color}
                icon={profileType?.icon}
                size='sm'
              >
                {profileType?.name}
              </Tag>
            ))}
          </div>
        </div>
      </div>
    </Link>
  )
};

export default UserItem;