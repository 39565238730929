import Link from "next/link"
import Image from "next/image"
import XCircleIcon from '@apg.gg/icons/lib/XCircleIcon';
import { RecentProfile, db } from "./database.config";

const recentPagesMapper = (
  recentProfiles: RecentProfile[],
  locale: string
) => {
  if (recentProfiles && typeof recentProfiles === 'object' && Object.keys(recentProfiles).length > 0) {
    if (recentProfiles && Array.isArray(recentProfiles) && recentProfiles.length > 0) {
      return recentProfiles.slice(0, 3).map((page: any) => ({
        ...page,
        href: `/${locale}${page?.href}`,
        linkComponent: Link,
        icon: (
          <div className="rounded-full relative w-10 h-10 group">
            <Image 
              src={page?.icon || ''} 
              className="rounded-full" 
              alt={page?.name || 'APG Profile'}
              width={40}
              height={40}
            />
    
            <XCircleIcon 
              className="absolute -top-1 -right-1 text-sm hidden group-hover:block text-white z-20"
              onClick={async (event: any) => {
                event.stopPropagation();
                event.preventDefault();

                const recentProfilesArray = recentProfiles.filter((profile: any) => profile.key !== page.key);

                await db.recentProfiles.put({
                  profiles: recentProfilesArray,
                }, 'user:local:recentProfiles');
              }}
            />
          </div>
        ),
      }));
    }
  }
  
  return [];
};

export default recentPagesMapper;