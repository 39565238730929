import Drawer from '@apg.gg/core/lib/Drawer';
import { useEffect, useState } from 'react';
import delay from '@apg.gg/core/lib/delay';
import { SubItemProps } from '@apg.gg/core/lib/Sidebar';
import { ApgUser } from '@/domain/account.interface';
import renderIcon from '@apg.gg/core/lib/renderIcon';
import Link from 'next/link';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import { useTranslations } from 'next-intl';

export interface EditorDrawerProps {
  isOpen: boolean;
  locale: string;
  onClose: () => void;
  profileMenu: SubItemProps[];
  userProfile: ApgUser;
}

const EditorDrawer: React.FC<EditorDrawerProps> = ({
  isOpen,
  locale,
  onClose,
  profileMenu,
  userProfile
}) => {
  const t = useTranslations('Profile');
  const { asPath } = useRouter();
  const [showDrawer, setShowDrawer] = useState(false);
  
  useEffect(() => {
    if (isOpen) {
      delay(() => setShowDrawer(true));
    }
  }, [isOpen])

  const handleOnClose = () => {
    setShowDrawer(false);
    delay(() => onClose());
  }

  return (
    <Drawer
      position="left"
      width="260px"
      className="ml-16"
      isOpen={showDrawer}
      onClose={handleOnClose}
      disableClickOutsideToClose={false}
      content={
        <div className="flex flex-col gap-4 w-full py-10">
          <div className="flex gap-1 rounded-full border border-purple-400 justify-center h-10 items-center">
            <h5 className="text-purple-400 text-label-lg uppercase">{t('active-sections')}</h5>
          </div>

          <div className="flex flex-col gap-2">
            {profileMenu.map((item, index) => {

              if (item.type === "divider") {
                return (
                  <div key={`${item.key}-${index}`} className="border-b border-white border-opacity-50 my-2" />
                )
              }

              return (
                <Link
                  href={item.href || '/'}
                  key={`${item.key}-${index}`}
                  className={classNames(
                    "flex gap-2 items-center cursor-pointer p-2 group",
                    asPath === item.href ? ' text-purple' : 'text-white'
                  )}
                  onClick={handleOnClose}
                >
                  {renderIcon(item.icon, 'group-hover:text-purple text-xl')}
                  <span className="text-label-md group-hover:text-purple">{item.name}</span>
                </Link>
              )
            })}
          </div>
        </div>
      }
    />
  );
};

export default EditorDrawer;
