import Link from "next/link";
import Image from "next/image";
import { Recent, RecentSearch, db } from "@/lib/utils/database.config";
import XIcon from "@apg.gg/icons/lib/XIcon";
import classNames from "classnames";
import _ from "lodash";
import { useTranslations } from "next-intl";

export type RecentSearchItemProps = {
  recentSearch: Recent;
  onSave: (recentSearch: any) => void;
  handleOnClose: () => void;
  recentSearches: RecentSearch;
}

const RecentSearchItem = ({ 
  recentSearch, 
  onSave, 
  handleOnClose,
  recentSearches
}: RecentSearchItemProps) => {
  const t = useTranslations('Profile');
  
  return (
    <Link
      href={`${recentSearch.link}` || '/'}
      key={`${recentSearch.id}`}
      className="flex flex-col gap-2 items-start w-full justify-center mb-2"
      onClick={() => {
        onSave(recentSearch);
        handleOnClose();
      }}
    >
      <div className="flex gap-2 items-center w-full">
        <Image 
          src={recentSearch.image || `${process.env.NEXT_PUBLIC_DEFAULT_PROFILE_PIC}`} 
          alt={recentSearch.username} 
          width={40} 
          height={40}
          className={classNames(
            "overflow-hidden w-10 h-10 object-cover object-center",
            {
              "rounded-full": recentSearch.type === 'user',
              "rounded": recentSearch.type === 'event' || recentSearch.type === 'achievement' || recentSearch.type === 'game',
            }
          )}
        />
        <div className="flex flex-col">
          <span className="text-body-md text-white">{_.truncate(recentSearch.title, { length: 20, separator: '...' })}</span>
          {recentSearch.type === 'user' && (
            <span className="text-body-sm text-black-400">@{recentSearch.username}</span>
          )}
          {recentSearch.type === 'event' && (
            <span className="text-body-sm text-black-400">{recentSearch.game} - {_.capitalize(recentSearch.eventType)}</span>
          )}
          {recentSearch.type === 'achievement' && (
            <>
              <span className="text-body-sm text-black-400">{recentSearch.game}</span>
              <span className="text-body-sm text-black-400">@{recentSearch.username}</span>
            </>
          )}
          {recentSearch.type === 'game' && (
            <>
              <span className="text-body-sm text-black-400">@{recentSearch.username}</span>
            </>
          )}
        </div>

        <div className="flex ml-auto h-8 items-center">
          <XIcon 
            className="text-sm text-white z-20 "
            onClick={async (event: any) => {
              event.stopPropagation();
              event.preventDefault();

              const recentProfilesArray = recentSearches.recentSearches.filter((profile: any) => profile.id !== recentSearch.id);

              await db.recentSearches.put({
                recentSearches: recentProfilesArray,
              }, 'user:local:recentSearches');
            }}
          />
        </div>
      </div>
    </Link>
  )
};

export default RecentSearchItem;