import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";
import dayjs from 'dayjs';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const CAN_USE_DOM: boolean =
  typeof window !== "undefined" &&
  typeof window.document !== "undefined" &&
  typeof window.document.createElement !== "undefined";

export const getDateQuery = (dateType: string) => {
  switch (dateType) {
    case "today":
      return { "_eq": dayjs().format("YYYY-MM-DD") }
    case "tomorrow":
      return { "_eq": dayjs().add(1, 'day').format("YYYY-MM-DD") }
    case "this-week":
      return { "_gte": dayjs().startOf('week').format("YYYY-MM-DD"), "_lte": dayjs().endOf('week').format("YYYY-MM-DD") }
    case "next-week":
      return { "_gte": dayjs().add(1, 'week').startOf('week').format("YYYY-MM-DD"), "_lte": dayjs().add(1, 'week').endOf('week').format("YYYY-MM-DD") }
    case "this-month":
      return { "_gte": dayjs().startOf('month').format("YYYY-MM-DD"), "_lte": dayjs().endOf('month').format("YYYY-MM-DD") }
    case "next-month":
      return { "_gte": dayjs().add(1, 'month').startOf('month').format("YYYY-MM-DD"), "_lte": dayjs().add(1, 'month').endOf('month').format("YYYY-MM-DD") }
    case "this-year":
      return { "_gte": dayjs().startOf('year').format("YYYY-MM-DD"), "_lte": dayjs().endOf('year').format("YYYY-MM-DD") }
    case "past":
      return { "_lte": dayjs().format("YYYY-MM-DD") }
    case "futures":
      return { "_gte": dayjs().format("YYYY-MM-DD") }
    default:
      return {}
  }
}

export const dateOptions = (t: any) => [
  {
    label: t("label-date-all"),
    value: "all"
  },
  {
    label: t("label-date-today"),
    value: "today"
  },
  {
    label: t("label-date-tomorrow"),
    value: "tomorrow"
  },
  {
    label: t("label-date-this-week"),
    value: "this-week"
  },
  {
    label: t("label-date-next-week"),
    value: "next-week"
  },
  {
    label: t("label-date-this-month"),
    value: "this-month"
  },
  {
    label: t("label-date-next-month"),
    value: "next-month"
  },
  {
    label: t("label-date-this-year"),
    value: "this-year"
  },
  {
    label: t("label-date-past"),
    value: "past"
  },
  {
    label: t("label-date-future"),
    value: "futures"
  }
];