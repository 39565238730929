import { ApgUser } from "@/domain/account.interface";
import useClaim from "@/hooks/useClaim";
import useAuthStore from "@/store/auth.store";
import Button from "@apg.gg/core/lib/Button";
import { cn } from "@apg.gg/core/lib/cn";
import { FC, useState } from "react";

export type CardQuestProps = {
  missionId: number;
  userId: number;
  status: "pending" | "completed" | "failed";
  title: string;
  description: string;
  category: string;
  coinsReward: number;
  requirements: Record<string, any>;
  counter: number;
  translations: {
    buttonLabel: string;
    coinsLabel: string;
    completedLabel: string;
    claimedLabel: string;
    claimSucess: string;
  }
  onCompleteClaim: () => void;
};

const CardQuest: FC<CardQuestProps> = ({
  missionId,
  userId,
  status,
  title,
  description,
  category,
  coinsReward,
  requirements,
  counter,
  translations: {
    buttonLabel,
    coinsLabel,
    completedLabel,
    claimedLabel,
    claimSucess
  }
}) => {
  const { authToken, userData, setUserData } = useAuthStore();
  const [isClaimed, setIsClaimed] = useState(false);
  const useClaimMutation = useClaim(userId, authToken, missionId, claimSucess);

  const onHandleClaim = async () => {
    const { success } = await useClaimMutation.mutateAsync();

    if (success) {
      setUserData({
        ...userData,
        totalCoins: (userData?.totalCoins || 0) + coinsReward
      } as ApgUser);

      setIsClaimed(true);
    }
  }

  return (
    <div className="bg-black-800 rounded-2xl flex flex-col gap-4 p-4">
      <div className="flex justify-between">
        <Button
          type="ghost"
          fontSize="xs"
          onClick={() => console.log('clicked')}
        >
          {category}
        </Button>

        <Button 
          type="primary"
          onClick={onHandleClaim}
          fontSize="sm"
          className="h-8"
          disabled={status !== 'completed' || isClaimed}
        >
          {isClaimed ? claimedLabel : buttonLabel}
        </Button>
      </div>

      <div className="flex flex-col">
        <h3 className="text-title-md text-white uppercase">{title}</h3>
        <p className="text-body-md text-white-300">{description}</p>
      </div>

      <div className="flex">
        <div className="bg-black/40 rounded-2xl h-9 px-4 flex items-center flex-initial">
          <span className="text-title-sm text-white">+{coinsReward} {coinsLabel}</span>
        </div>
      </div>

      <div className="flex gap-4 items-center">
        <div className="bg-black-900 h-2 flex flex-grow rounded-2xl">
          {requirements.count ? (
            <div className="bg-green h-full rounded-2xl" style={{ width: `${(counter / requirements.count) * 100}%` }}></div>
          ) : (
            <div className="bg-green h-full rounded-2xl" style={{ width: `${(counter / 1) * 100}%` }}></div>
          )}
        </div>
        <span className={cn(
          "text-white text-title-sm uppercase font-bold",
          status === 'completed' ? 'text-green' : 'text-white-300'
        )}>
          {status === 'completed' ? completedLabel : 
            requirements.count ? `${counter}/${requirements.count}` : `${(counter / 1) * 100}%`
          }
        </span>
      </div>
    </div>
  );
}

export default CardQuest;