import Drawer from '@apg.gg/core/lib/Drawer';
import { useEffect, useState } from 'react';
import delay from '@apg.gg/core/lib/delay';
import renderIcon from '@apg.gg/core/lib/renderIcon';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import classNames from 'classnames';
import { subItemsAccount } from '@/layouts/customMenus';
import { useTranslations } from 'next-intl';

export interface AccountDrawerProps {
  isOpen: boolean;
  locale: string;
  onClose: () => void;
}

const AccountDrawer: React.FC<AccountDrawerProps> = ({
  isOpen,
  locale,
  onClose
}) => {
  const t = useTranslations('Account');
  const pathnames = usePathname();
  const [showDrawer, setShowDrawer] = useState(false);
  
  useEffect(() => {
    if (isOpen) {
      delay(() => setShowDrawer(true));
    }
  }, [isOpen])

  const handleOnClose = () => {
    setShowDrawer(false);
    delay(() => onClose());
  }

  return (
    <Drawer
      position="left"
      className="ml-16"
      isOpen={showDrawer}
      onClose={handleOnClose}
      disableClickOutsideToClose={false}
      classNames={{
        wrapper: "w-72 md:w-80",
        content: "w-72 md:w-80"
      }}
      content={
        <div className="flex flex-col gap-4 w-full py-10">
          <div className="flex gap-1 rounded-full border border-blue-400 justify-center h-10 items-center">
            <h5 className="text-blue-400 text-label-lg uppercase">{t('sidebar.account')}</h5>
          </div>

          <div className="flex flex-col gap-2">
            {subItemsAccount(t).map((item, index) => {
              if (item.type === "divider") {
                return (
                  <div key={`${item.key}-${index}`} className="border-b border-white border-opacity-50 my-2" />
                )
              } 

              return (
                <Link
                  href={`/${locale}/${item.href}` || '/'}
                  key={`${item.key}-${index}`}
                  className={classNames(
                    "flex gap-2 items-center cursor-pointer p-2 group",
                    pathnames === `/${locale}/${item.href}` ? ' text-blue' : 'text-white'
                  )}
                  onClick={handleOnClose}
                >
                  {renderIcon(item.icon, '')}
                  <span className="text-label-md group-hover:text-blue">{item.name}</span>

                  {item.isNew ? (
                    <div className="flex items-center justify-center bg-yellow rounded-full px-2 h-4">
                      <span className="text-black text-xs font-bold">{t("new")}</span>
                    </div>
                  ) : null}
                </Link>
              )
            })}
          </div>
        </div>
      }
    />
  );
};

export default AccountDrawer;
