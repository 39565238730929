import { apgGraphQL } from "../apgApi"
import { RequestError } from "../general.endpoints";
import { operationMissions } from "./queries";

export const getError = ({ title, message, response }: RequestError): RequestError => ({ title, message, response })

export interface EditProfileSetProps<T> {
  userId?: number;
  token?: string;
  data: T;
}

const updateLastLogin = async ({
  userId,
  token,
}: { 
  userId: number,
  token: string
}): Promise<{
  success: boolean,
  missionCompleted: boolean
}> => {
  const { data, errors } = await apgGraphQL(
    operationMissions,
    'RegisterLastLogin',
    {
      "userId": userId
    },
    token
  )

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  return data;
}

export const MissionsEndpoints = {
  updateLastLogin
}
