import { GameSearch } from "@/domain/account.interface";
import Link from "next/link";
import Image from "next/image";
import _ from "lodash";
import { useParams } from "next/navigation";

export type GameItemProps = {
  recentSearch: GameSearch;
  onSave: (recentSearch: GameSearch) => void;
  handleOnClose: () => void;
}

const GameItem = ({ 
  recentSearch, 
  onSave, 
  handleOnClose
}: GameItemProps) => {
  const params = useParams();
  const { locale } = params;
  
  return (
    <Link
      href={`/${locale}/games/${recentSearch.slug}` || '/'}
      key={`${recentSearch.id}`}
      className="flex flex-col gap-2 items-start mb-2 justify-center hover:scale-110 transition-all duration-300 ease-in-out"
      onClick={() => {
        onSave(recentSearch);
        handleOnClose();
      }}
    >
      <div className="flex gap-2 items-start w-full">
        <Image 
          src={recentSearch.thumbnail || `${process.env.NEXT_PUBLIC_DEFAULT_PROFILE_PIC}`} 
          alt={recentSearch.name} 
          width={65} 
          height={98}
          className="rounded w-[65px] h-[98px]"
        />
      </div>
    </Link>
  )
};

export default GameItem;