import Drawer from '@apg.gg/core/lib/Drawer';
import { useEffect, useState, useRef } from 'react';
import delay from '@apg.gg/core/lib/delay';
import { UserSearch } from '@/domain/account.interface';
import { MeiliSearch, MultiSearchResponse } from 'meilisearch'
import Input from '@apg.gg/core/lib/Input';
import { Recent, db } from '@/lib/utils/database.config';
import RecentSearchItem from './Hits/RecentSearchItem';
import { useLiveQuery } from 'dexie-react-hooks';
import EventItem from './Hits/EventItem';
import GameItem from './Hits/GameItem';
import AchievementItem from './Hits/AchievementItem';
import UserItem from './Hits/UserItem';
import { useTranslations } from 'next-intl';

const client = new MeiliSearch({
  host: `${process.env.NEXT_PUBLIC_MEILISEARCH_HOST}`,
  apiKey: `${process.env.NEXT_PUBLIC_MEILISEARCH_API_KEY}`,
});

export interface SearchDrawerProps {
  isOpen: boolean;
  locale: string;
  onClose: () => void;
}

const SearchDrawer: React.FC<SearchDrawerProps> = ({
  isOpen,
  locale,
  onClose
}) => {
  const t = useTranslations('Profile');
  const [showDrawer, setShowDrawer] = useState(false);
  const [searchedWord, setSearch] = useState('')
  const [resultSearch, setResults] = useState<MultiSearchResponse<UserSearch>>();
  const recentSearches = useLiveQuery(() => db.recentSearches.get("user:local:recentSearches"));
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isOpen) {
      delay(() => setShowDrawer(true));
    }
  }, [isOpen])

  const handleOnClose = () => {
    setShowDrawer(false);
    delay(() => onClose());
  }

  const getRecent = (hit: any, type: string): Recent => {
    switch (type) {
      case 'user':
        return {
          id: hit.id,
          title: hit.name,
          link: `/${locale}/${hit.username}`,
          username: hit.username,
          image: hit.profileImage || `${process.env.NEXT_PUBLIC_DEFAULT_PROFILE_PIC}`,
          type
        } as Recent;
      case 'event':
        return {
          id: hit.id,
          title: hit.title,
          link: `/${locale}/events/${hit.slug || hit.id}`,
          username: hit.user?.username,
          image: hit.image ?? `${process.env.NEXT_PUBLIC_DEFAULT_PROFILE_PIC}`,
          eventType: hit.eventType.name,
          game: hit.game ? hit.game.name : 'General',
          type
        } as Recent;
      case 'achievement':
        return {
          id: hit.id,
          title: hit.title,
          link: `/${locale}/achievements/${hit.slug || hit.id}`,
          username: hit.user?.username,
          image: hit.image ?? `${process.env.NEXT_PUBLIC_DEFAULT_PROFILE_PIC}`,
          game: hit.game?.name,
          type
        } as Recent;
      case 'game':
        return {
          id: hit.id,
          title: hit.name,
          link: `/${locale}/games/${hit.slug}`,
          username: hit.slug,
          image: hit.thumbnail || `${process.env.NEXT_PUBLIC_DEFAULT_PROFILE_PIC}`,
          type
        } as Recent;
      default:
        return {
          id: hit.id,
          title: hit.title,
          username: hit.user.username,
          link: `/${locale}/${hit.username}`,
          image: hit.profileImage || `${process.env.NEXT_PUBLIC_DEFAULT_PROFILE_PIC}`,
          type
        } as Recent;
    }
  }

  const onSave = async (hit: any, type: string, research = false) => {
    const recentSearch: Recent = !research ? getRecent(hit, type) : hit;

    let recentSearchesArray = recentSearches?.recentSearches || [];

    const existingIndex = recentSearchesArray.findIndex((item: Recent) => item.id === hit.id);

    if (existingIndex !== -1) {
      recentSearchesArray.splice(existingIndex, 1);
    }

    recentSearchesArray = [recentSearch, ...recentSearchesArray];

    try {
      await db.recentSearches.put({
        recentSearches: recentSearchesArray,
      }, "user:local:recentSearches");
    } catch (error) {
      console.error(`Failed to add recentSearch: ${error}`);
    }
  }

  useEffect(() => {
    // Create a scoped async function in the hook
    async function searchWithMeili() {
      const search: MultiSearchResponse<UserSearch> = await client.multiSearch({ queries: [
        {
          indexUid: 'users-collections',
          q: searchedWord,
          limit: 4,
        },
        {
          indexUid: 'achievements-collections',
          q: searchedWord,
          limit: 4,
        },
        {
          indexUid: 'events-collections',
          q: searchedWord,
          limit: 4,
        },
        {
          indexUid: 'games-collections',
          q: searchedWord,
          limit: 4,
        },
      ]});
      setResults(search);
    }
    // Execute the created function directly
    searchWithMeili();
  }, [searchedWord]);

  return (
    <Drawer
      position="left"
      className="ml-16"
      classNameContent="!p-0"
      isOpen={showDrawer}
      onClose={handleOnClose}
      disableClickOutsideToClose={false}
      classNames={{
        wrapper: "w-72 md:w-80",
        content: "w-72 md:w-80"
      }}
      content={
        <div className="flex flex-col gap-4 w-full pt-10">
          <div className="px-4">
            <Input
              ref={inputRef}
              value={searchedWord}
              onChange={(event) => setSearch(event.target.value)}
              className="w-full"
              placeholder="Search"
              clearable
            />
          </div>

          <div className="scrollbar-small-content">
            <div className="overflow-y-auto h-[calc(100vh-160px)] p-4 space-y-4">
              {searchedWord === '' ? (
                <div className="flex flex-col">
                  <h4 className="text-title-sm uppercase mb-2">{t("search.title-recent-searches")}</h4>
                  {!recentSearches || recentSearches?.recentSearches.length === 0 ? (
                    <span className="text-body-sm text-black-400">{t("search.error-recent-searches-not-found")}</span>
                  ) : (
                    <>
                      {recentSearches?.recentSearches.map((hit: Recent) => (
                        <RecentSearchItem 
                          key={hit.id}
                          recentSearch={hit}
                          onSave={(hit) => {
                            onSave(hit, hit.type, true);
                          }}
                          handleOnClose={handleOnClose} 
                          recentSearches={recentSearches}
                        />
                      ))}
                    </>
                  )}
                </div>
              ) : (
                <>
                  <div className="flex flex-col">
                    <h4 className="text-title-sm uppercase mb-2">{t("search.title-profiles")}</h4>
                    {resultSearch?.results[0].hits.length === 0 ? (
                      <span className="text-body-sm text-black-400 mt-0">{t("search.error-profiles-not-found")}</span>
                    ) : (
                      <>
                        {resultSearch?.results[0].hits.map((hit: any) => (
                          <UserItem 
                            key={hit.id} 
                            user={hit} 
                            onSave={(hit) => onSave(hit, 'user')}
                            handleOnClose={handleOnClose} 
                          />
                        ))}
                      </>
                    )}
                  </div>

                  <div className="flex flex-col">
                    <h4 className="text-title-sm uppercase mb-2">{t("search.title-achievements")}</h4>
                    {resultSearch?.results[1].hits.length === 0 ? (
                      <span className="text-body-sm text-black-400 mt-0">{t("search.error-achievements-not-found")}</span>
                    ) : (
                      <>
                        {resultSearch?.results[1].hits.map((hit: any) => (
                          <AchievementItem 
                            key={hit.id} 
                            recentSearch={hit} 
                            onSave={(hit) => onSave(hit, 'achievement')}
                            handleOnClose={handleOnClose} 
                          />
                        ))}
                      </>
                    )}
                  </div>

                  <div className="flex flex-col">
                    <h4 className="text-title-sm uppercase mb-2">{t("search.title-events")}</h4>
                    {resultSearch?.results[2].hits.length === 0 ? (
                      <span className="text-body-sm text-black-400 mt-0">{t("search.error-events-not-found")}</span>
                    ) : (
                      <>
                        {resultSearch?.results[2].hits.map((hit: any) => (
                          <EventItem 
                            key={hit.id} 
                            recentSearch={hit} 
                            onSave={(hit) => onSave(hit, 'event')}
                            handleOnClose={handleOnClose} 
                          />
                        ))}
                      </>
                    )}
                  </div>

                  <div className="flex flex-col">
                    <h4 className="text-title-sm uppercase mb-2">{t("search.title-games")}</h4>
                    {resultSearch?.results[3].hits.length === 0 ? (
                      <span className="text-body-sm text-black-400 mt-0">{t("search.error-games-not-found")}</span>
                    ) : (
                      <div className="flex flex-wrap gap-1">
                        {resultSearch?.results[3].hits.map((hit: any) => (
                          <GameItem 
                            key={hit.id} 
                            recentSearch={hit} 
                            onSave={(hit) => onSave(hit, 'game')}
                            handleOnClose={handleOnClose} 
                          />
                        ))}
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      }
    />
  );
};

export default SearchDrawer;
